/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  width: 100vw;
  margin: 0; 
  padding: 0; 
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif, "Helvetica Neue";
  background-color: rgb(255, 255, 255);
}

a,
button {
  background-color: #A0D4AB !important;
  color: black !important;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0px;
  border-radius: 15px;
  height: auto;
  width: 80vw;
}
